import type { MutationHookOptions, TypedDocumentNode } from '@apollo/client';
import { useMutation } from '@apollo/client';

import {
  useApolloContextUriWithTestMode,
  usePublicApolloContextUriWithTestMode,
} from './useApolloContextUriWithTestMode';

export function useMutationWithTestMode<U, V>(
  document: TypedDocumentNode<U, V>,
  baseOptions: MutationHookOptions<U, V> = {},
  isPublic = false,
) {
  const publicContextUri = usePublicApolloContextUriWithTestMode();
  const privateContextUri = useApolloContextUriWithTestMode();
  const contextUri = isPublic ? publicContextUri : privateContextUri;

  const returnResult = useMutation(document, {
    ...baseOptions,
    context: {
      ...contextUri,
    },
  });

  return returnResult;
}
